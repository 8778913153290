import { TextStyle } from 'react-native';

import design_tokens from './design_tokens/common.json';

export type DesignTokens = typeof design_tokens;

type NestedKeys<T> = T extends object
  ? {
      [K in keyof T]: K extends string ? (T[K] extends object ? `${K}/${NestedKeys<T[K]>}` | K : K) : never;
    }[keyof T]
  : never;

export type ValidStylePath = NestedKeys<DesignTokens>;
export type ValidTypographyPath = `${NestedKeys<DesignTokens['typography']>}`;
export type ValidColorsPath = `${NestedKeys<DesignTokens['colors']>}`;
export type ValidPalettePath = `${NestedKeys<DesignTokens['palette']>}`;
export type ValidGradientsPath = `${NestedKeys<DesignTokens['gradients']>}`;
export type ValidLinearGradientsPath = `${NestedKeys<DesignTokens['gradients']['linear']>}`;
export type ValidAngularGradientsPath = `${NestedKeys<DesignTokens['gradients']['angular']>}`;
export type ValidRadialGradientsPath = `${NestedKeys<DesignTokens['gradients']['radial']>}`;
export type ValidMixinsPath = `${NestedKeys<DesignTokens['mixins']>}`;
export type ValidSizesPath = `${NestedKeys<DesignTokens['sizes']>}`;
export type ValidSpacingsPath = `spacings/${NestedKeys<DesignTokens['sizes']['spacings']>}`;

type FontWeight = {
  [key: string]: TextStyle['fontWeight'];
};

const styles = {
  ...design_tokens,
  typography: {
    ...design_tokens.typography,
    font_weight: design_tokens.typography.font_weight as FontWeight,
  },
};

export default styles;

export type Styles = typeof styles;
