import { Platform } from 'react-native';

import THUMB from 'screens/palm-reading-daily/components/report/sections/finger/images/thumb.png';
import INDEX from 'screens/palm-reading-daily/components/report/sections/finger/images/index-finger.png';
import MIDDLE from 'screens/palm-reading-daily/components/report/sections/finger/images/middle-finger.png';
import RING from 'screens/palm-reading-daily/components/report/sections/finger/images/ring-finger.png';
import LITTLE from 'screens/palm-reading-daily/components/report/sections/finger/images/little-finger.png';

import { DIFFICULT, ENGAGED, MARRIED, SINGLE, SOULMATE } from './profile';

export const PALM_DETECTION_API_BASE = 'https://hand-detection.astroline.today';
export const DETECT_API_MODE_POINTS = `${PALM_DETECTION_API_BASE}/palm/`;
export const DETECT_API_MODE_LINES = `${PALM_DETECTION_API_BASE}/lines/`;

export const PALM = 'PALM';

export const SEEDS = {
  HEART: 'heart',
  LIFE: 'life',
  HEAD: 'head',
  FATE: 'fate',
  LOGIC_INTUITION: 'logic_intuition',
  ETHICS_SENSATION: 'ethic_sensation',
  INTROVERTED_EXTROVERTED: 'introverted_extroverted',
  HEART_DIFF: 'heart_diff',
  LIFE_DIFF: 'life_diff',
  HEAD_DIFF: 'head_diff',
  FATE_DIFF: 'fate_diff',
  EARNINGS_20: 'earnings_20',
  EARNINGS_40: 'earnings_40',
  EARNINGS_60: 'earnings_60',
  EARNINGS_80: 'earnings_80',
  YOUR_EARNING: 'your_earnings',
  RISK_RESPIRATORY_DISEASE: 'risk_respiratory_disease',
  RISK_ACCIDENT: 'risk_accident',
  RISK_HEART_DISEASE: 'risk_heart_disease',
  RISK_CANCER: 'risk_cancer',
  RISK_ALZHEIMER: 'risk_alzheimer',
  RISK_STROKE: 'risk_stroke',
  MARRIAGE_20: 'marriage_20',
  MARRIAGE_40: 'marriage_40',
  MARRIAGE_60: 'marriage_60',
  MARRIAGE_80: 'marriage_80',
  YOUR_MARRIAGE: 'your_marriage',
  MARRIAGE_AGE: 'marriage_age',
  CHILDREN_0: 'children_0',
  CHILDREN_1: 'children_1',
  CHILDREN_2: 'children_2',
  CHILDREN_3: 'children_3',
  CHILDREN_X: 'children_x',
  LANGUAGE_LARGEST_INDEX: 'language_largest_index',
  LANGUAGE_LARGEST_PERCENTAGE: 'language_largest_percentage',
};

export const LEFT_HAND_PERCENTAGE = {
  MIN: 65,
  MAX: 95,
};

export const RIGHT_HAND_DIFF = {
  MIN: -10,
  MAX: 10,
};

export const RISK_PERCENTAGES = {
  [SEEDS.RISK_RESPIRATORY_DISEASE]: 5,
  [SEEDS.RISK_ACCIDENT]: 6,
  [SEEDS.RISK_HEART_DISEASE]: 23,
  [SEEDS.RISK_CANCER]: 21,
  [SEEDS.RISK_ALZHEIMER]: 4,
  [SEEDS.RISK_STROKE]: 5,
};

export const EARNINGS_PERCENTAGES = {
  [SEEDS.EARNINGS_20]: { MIN: 30, MAX: 40 },
  [SEEDS.EARNINGS_40]: { MIN: 70, MAX: 83 },
  [SEEDS.EARNINGS_60]: { MIN: 50, MAX: 60 },
  [SEEDS.EARNINGS_80]: { MIN: 40, MAX: 50 },
};

export const EARNINGS_PERCENTAGE_NEXT = 80;
export const EARNINGS_PERCENTAGE_FIRST = 0;

export const MARRIAGE_PERCENTAGES = {
  [SEEDS.MARRIAGE_20]: { MIN: 45, MAX: 55 },
  [SEEDS.MARRIAGE_40]: { MIN: 50, MAX: 65 },
  [SEEDS.MARRIAGE_60]: { MIN: 30, MAX: 45 },
  [SEEDS.MARRIAGE_80]: { MIN: 15, MAX: 30 },
};

export const MIN_MARRIAGE_AGE = 20;
export const MAX_MARRIAGE_AGE = 80;
export const MARRIAGE_AGE_DIFF: { [key: string]: any } = {
  [SINGLE]: { MIN: 1, MAX: 5 },
  [MARRIED]: { MIN: -1, MAX: -5 },
  [SOULMATE]: { MIN: 0, MAX: 3 },
  [ENGAGED]: { MIN: 0, MAX: 2 },
  [DIFFICULT]: { MIN: 1, MAX: 5 },
};

export const TRAITS = {
  LOGIC: 'LOGIC',
  ETHICS: 'ETHICS',
  INTROVERTED: 'INTROVERTED',
  INTUITION: 'INTUITION',
  SENSATION: 'SENSATION',
  EXTROVERTED: 'EXTROVERTED',
};

export type TraitsType = keyof typeof TRAITS;

export type TraitsMap = {
  [key in TraitsType]: boolean;
};

export const FINGERS = {
  THUMB: 'THUMB',
  INDEX: 'INDEX',
  MIDDLE: 'MIDDLE',
  RING: 'RING',
  LITTLE: 'LITTLE',
};

export const TRAITS_ARRAY = Object.values(TRAITS);

export enum STEPS {
  LEFT_HAND = 'LEFT_HAND',
  RIGHT_HAND = 'RIGHT_HAND',
  FINGERS = 'FINGERS',
}
export type Steps = (typeof STEPS)[keyof typeof STEPS];

export const STEPS_ORDER = [STEPS.LEFT_HAND, STEPS.RIGHT_HAND, STEPS.FINGERS];
export const STEPS_WITH_RIGHT_HAND = [STEPS.RIGHT_HAND, STEPS.FINGERS];

export enum WHOSE_HAND_TYPES {
  MY_HAND = 'MY_HAND',
  NOT_MINE = 'NOT_MINE',
}

interface Point {
  x: number;
  y: number;
}

export const POINTS: Point[] = Platform.select({
  android: [
    {
      y: 571,
      x: 331,
    },
    {
      y: 525,
      x: 243,
    },
    {
      y: 459,
      x: 178,
    },
    {
      y: 384,
      x: 158,
    },
    {
      y: 324,
      x: 131,
    },
    {
      y: 357,
      x: 266,
    },
    {
      y: 277,
      x: 250,
    },
    {
      y: 226,
      x: 244,
    },
    {
      y: 178,
      x: 241,
    },
    {
      y: 351,
      x: 316,
    },
    {
      y: 258,
      x: 313,
    },
    {
      y: 198,
      x: 311,
    },
    {
      y: 146,
      x: 308,
    },
    {
      y: 363,
      x: 360,
    },
    {
      y: 282,
      x: 375,
    },
    {
      y: 232,
      x: 377,
    },
    {
      y: 185,
      x: 376,
    },
    {
      y: 389,
      x: 404,
    },
    {
      y: 322,
      x: 421,
    },
    {
      y: 278,
      x: 429,
    },
    {
      y: 234,
      x: 433,
    },
  ],
  ios: [
    {
      y: 542,
      x: 271,
    },
    {
      y: 513,
      x: 199,
    },
    {
      y: 458,
      x: 144,
    },
    {
      y: 399,
      x: 118,
    },
    {
      y: 354,
      x: 88,
    },
    {
      y: 354,
      x: 214,
    },
    {
      y: 277,
      x: 198,
    },
    {
      y: 228,
      x: 192,
    },
    {
      y: 184,
      x: 189,
    },
    {
      y: 345,
      x: 257,
    },
    {
      y: 260,
      x: 251,
    },
    {
      y: 206,
      x: 248,
    },
    {
      y: 159,
      x: 246,
    },
    {
      y: 355,
      x: 295,
    },
    {
      y: 279,
      x: 307,
    },
    {
      y: 230,
      x: 309,
    },
    {
      y: 187,
      x: 309,
    },
    {
      y: 379,
      x: 332,
    },
    {
      y: 317,
      x: 352,
    },
    {
      y: 276,
      x: 362,
    },
    {
      y: 236,
      x: 369,
    },
  ],
  web: [
    {
      y: 542,
      x: 271,
    },
    {
      y: 513,
      x: 199,
    },
    {
      y: 458,
      x: 144,
    },
    {
      y: 399,
      x: 118,
    },
    {
      y: 354,
      x: 88,
    },
    {
      y: 354,
      x: 214,
    },
    {
      y: 277,
      x: 198,
    },
    {
      y: 228,
      x: 192,
    },
    {
      y: 184,
      x: 189,
    },
    {
      y: 345,
      x: 257,
    },
    {
      y: 260,
      x: 251,
    },
    {
      y: 206,
      x: 248,
    },
    {
      y: 159,
      x: 246,
    },
    {
      y: 355,
      x: 295,
    },
    {
      y: 279,
      x: 307,
    },
    {
      y: 230,
      x: 309,
    },
    {
      y: 187,
      x: 309,
    },
    {
      y: 379,
      x: 332,
    },
    {
      y: 317,
      x: 352,
    },
    {
      y: 276,
      x: 362,
    },
    {
      y: 236,
      x: 369,
    },
  ],
}) as Point[];

export const TOLERANCE: number = Platform.select({
  ios: 80,
  android: 150,
  web: 80,
}) as number;

export const SVG_ANIMATION_DURATION = 500;
export const EXTRA_TIMEOUT = 200;
export const LINE_ANIMATION_DURATION = SVG_ANIMATION_DURATION + EXTRA_TIMEOUT;

export enum ERRORS {
  NO_HAND = 'NO_HAND',
  INCORRECT = 'INCORRECT',
  NO_CAMERA_ACCESS = 'NO_CAMERA_ACCESS',
  NO_URI_GIVEN = 'NO_URI_GIVEN',
  TIMEOUT = 'TIMEOUT',
}

export enum PATH_TYPE {
  PREDICTIONS = 'Predictions',
  TODAY = 'Today',
  ONBOARDING = 'Onboarding',
  CHAT = 'Chat',
  MIGRATION = '_Migration', // TODO: TMP delete with migrate action after v4.34 release
}

export const LIFE_FROM_TO_MAP = {
  0: { from: 75, to: 78 },
  1: { from: 78, to: 81 },
  2: { from: 81, to: 84 },
  3: { from: 84, to: 87 },
  4: { from: 87, to: 89 },
};

export const TABS = ['YOUR_STORY', 'TODAY'];

export const FINGER_IMAGES = {
  [FINGERS.THUMB]: THUMB,
  [FINGERS.INDEX]: INDEX,
  [FINGERS.MIDDLE]: MIDDLE,
  [FINGERS.RING]: RING,
  [FINGERS.LITTLE]: LITTLE,
};
