import { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';

import { useIsForeground } from 'hooks/use-is-foreground';
import { getDiffSecondsFromNow } from 'utils/date';

const useCountDown = (date: Date, onTimeEnd?: () => void) => {
  const isForeground = useIsForeground();
  const [remainingSeconds, setRemainingSeconds] = useState(getDiffSecondsFromNow(date));

  const updateRemainingTime = useCallback(() => {
    setRemainingSeconds(getDiffSecondsFromNow(date));
  }, [date]);

  useEffect(() => {
    if (remainingSeconds <= 0) {
      if (typeof onTimeEnd === 'function') {
        onTimeEnd();
      }
    }
  }, [remainingSeconds, onTimeEnd]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;

    if (isForeground) {
      updateRemainingTime();
      interval = setInterval(updateRemainingTime, 1000);
    }

    return () => clearInterval(interval);
  }, [isForeground]);

  return {
    isTimeUp: remainingSeconds <= 0,
    remainingSeconds: remainingSeconds,
    formattedTime: remainingSeconds >= 3600 ? dayjs(remainingSeconds * 1000).format('HH:mm') : dayjs(remainingSeconds * 1000).format('mm:ss'),
  };
};

export default useCountDown;
