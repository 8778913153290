import { Platform } from 'react-native';
import uuid from 'react-native-uuid';

import { PalmMetadata, Report, Reports } from 'store/palm-reading/types';

import api from '..';

import { PalmReadingResponse } from './interfaces';

// old palm reading
const PALM_READING_V1 = '/image-recognition/palm-reading?hand_type=right';

const GET_REPORTS = '/palm/view';
const CREATE_REPORT = '/palm/create';

type Key = 'left' | 'right';

export const createPalmReport = (uri: string, metadata: PalmMetadata, hand: Key = 'left'): Promise<Report> => {
  if (Platform.OS === 'web') {
    return api.fetch(`${CREATE_REPORT}?key=${hand}`, {
      method: 'POST',
      data: {
        image_file: uri.replace('data:image/jpeg;base64,', ''),
        data: metadata,
      },
    });
  }

  const form = new FormData();
  form.append('image_file', {
    uri,
    type: 'image/jpg',
    name: `${uuid.v4()}.jpg`,
  } as unknown as Blob);

  form.append('data', JSON.stringify(metadata));

  return api.fetch(
    `${CREATE_REPORT}?key=${hand}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: form,
    },
    true,
  );
};

export const getPalmReports = (): Promise<Reports> => {
  return api.fetch(`${GET_REPORTS}`, {
    method: 'GET',
  });
};

//
//
// OLD PALM READING BELOW
//
//
export const getPalm = (): Promise<PalmReadingResponse> => {
  return api.fetch(PALM_READING_V1, {
    method: 'GET',
  });
};

export const postPalm = (uri: string): Promise<any> => {
  if (Platform.OS === 'web') {
    return api.fetch(PALM_READING_V1, {
      method: 'POST',
      data: {
        image_file: uri.replace('data:image/jpeg;base64,', ''),
      },
    });
  }

  const form = new FormData();
  form.append('image_file', {
    uri,
    type: 'image/jpg',
    name: `${uuid.v4()}.jpg`,
  } as unknown as Blob);

  return api.fetch(
    PALM_READING_V1,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: form,
    },
    true,
  );
};
