import React, { FC, useCallback, useMemo, useState } from 'react';
import { Image, ImageResizeMode, Platform, StyleSheet } from 'react-native';

import { useAppSelector } from 'store';
import { HANDS } from 'store/palm-reading/types';
import { getDocumentDirectory } from 'utils/cache-manager';

import LEFT_DEFAULT from '../../../assets/images/hands/left.png';
import RIGHT_DEFAULT from '../../../assets/images/hands/right.png';

interface Props {
  hand: HANDS;
  onError?: () => void;
  resizeMode?: ImageResizeMode;
}

const PhotoView: FC<Props> = ({ hand, onError, resizeMode = 'cover' }) => {
  const previews = useAppSelector(state => state.palmReading.previews);
  const preview = previews[hand];

  const [error, setError] = useState<boolean>(!preview?.uri?.length);

  const uri = useMemo(() => {
    if (error) {
      return HANDS.RIGHT === hand ? RIGHT_DEFAULT : LEFT_DEFAULT;
    }

    const path = Platform.OS === 'web' ? preview?.uri : getDocumentDirectory() + preview?.uri?.split('/')?.pop();
    return { uri: path };
  }, [error, hand, preview]);

  const handleError = useCallback(() => {
    setError(true);

    if (typeof onError === 'function') {
      onError();
    }
  }, [onError]);

  return <Image style={styles.root} source={uri} onError={handleError} resizeMode={resizeMode} />;
};

export default PhotoView;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
  },
});
