import { Linking, Platform } from 'react-native';
import { createAction } from 'redux-actions';
import moment from 'moment';
import { isNumber } from 'lodash';

import Analytics from 'analytics';
import { getReports } from 'api/purchases';
import { UserReport as UserReportAPIData } from 'api/purchases/interfaces';
import { AppDispatch, AppGetState, AppThunk } from 'store';
import { addNotificationToQueue } from 'store/notification-center/actions';
import { showModal } from 'store/modals/actions';
import { navigate } from 'store/navigation/actions';
import * as MODALS from 'constants/modals';
import { TYPES_OF_ISSUE, VIEW_TYPES, OPEN_REPORTS_PLACES, REPORTS_PRODUCT_CODES } from 'constants/upsale';
import { NOTIFICATIONS } from 'constants/notification-center';
import * as ROUTES from 'constants/routes';
import { addQueryParam } from 'utils/url';

import { TYPES, UserReport } from './types';
import { getAvailableReportsToBuy } from './selectors';

const isWeb = Platform.OS === 'web';

const setReports = createAction(TYPES.SET_REPORTS);
const setReportViewed = createAction(TYPES.SET_REPORT_VIEWED);

const prepareReports = (reports: UserReportAPIData[], viewedReports: UserReport['productCode'][] = []): UserReport[] => {
  const sortOrder = [TYPES_OF_ISSUE.ONE_TIME, TYPES_OF_ISSUE.SUB_GIFT, TYPES_OF_ISSUE.GIFT];
  return reports
    .map(report => {
      const { id, title, link, image, product_code, resource_type, view_type, ready_timer, type_of_issue } = report;

      const typeOfIssue = type_of_issue as TYPES_OF_ISSUE;
      const readyDate = new Date();

      if (isNumber(ready_timer)) {
        const currentSeconds = readyDate.getSeconds();
        const newSeconds = currentSeconds + ready_timer;
        readyDate.setSeconds(newSeconds);
      }

      return {
        id: id.toString(),
        title,
        image,
        link,
        isViewed: viewedReports.includes(product_code),
        resourceType: resource_type,
        productCode: product_code,
        viewType: view_type as VIEW_TYPES,
        typeOfIssue,
        isGift: typeOfIssue === TYPES_OF_ISSUE.GIFT,
        readyTimer: ready_timer,
        readyDate,
      } as UserReport;
    })
    .sort((a, b) => sortOrder.indexOf(a.typeOfIssue) - sortOrder.indexOf(b.typeOfIssue));
};

export const initUpsails = (): AppThunk => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { notificationCenter: config },
      },
    } = getState();

    const userReports = await dispatch(fetchReports());

    if (config?.pdfReports?.enabled && userReports.filter(report => !report.isViewed).length) {
      dispatch(addNotificationToQueue(NOTIFICATIONS.PERSONAL_REPORTS));
    }

    dispatch(showGetPDFReportsModal());
  };
};

export const fetchReports = (): AppThunk<Promise<UserReport[]>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      upsails: { userReports, viewedReports },
    } = getState();

    const { user_reports, available_reports } = await getReports();

    const preparedReports = prepareReports(user_reports, viewedReports);

    const reports = preparedReports.map(report => {
      const updatedReport = userReports.find(r => r.id === report.id);

      if (!updatedReport) {
        return report;
      }

      return {
        ...report,
        isViewed: updatedReport.isViewed,
      };
    });

    dispatch(setReports({ userReports: reports, availableReports: available_reports }));
    return reports;
  };
};

const downloadReport = (link: UserReport['link'], eventParams?: object): AppThunk => {
  return () => {
    Linking.openURL(addQueryParam(link, 'method', 'app'));
    Analytics.trackEvent('Download_Report', 'Success', { ...eventParams });
  };
};

export const openReport = (productCode: UserReport['productCode'], place: OPEN_REPORTS_PLACES, isTimeUp?: boolean): AppThunk => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { pdfReportsBannerConfig },
      },
      upsails: { userReports, viewedReports },
    } = getState();

    const report = userReports.find(r => r.productCode === productCode);

    if (!report) {
      return;
    }

    Analytics.track('Personal_Report_Link_Click', { report: productCode, place, withTimer: isNumber(report?.readyTimer) && !isTimeUp });

    const navigateToScreen = () => {
      const destination = report.productCode as REPORTS_PRODUCT_CODES;
      if (Object.keys(ROUTES.REPORT_ROUTES_MAP).includes(destination)) {
        navigate(ROUTES.REPORT_ROUTES_MAP[destination], { report: productCode, place });
      }
      return false;
    };

    if (!report.link || !isTimeUp) {
      return navigateToScreen();
    }

    if (!viewedReports.includes(report.productCode)) {
      dispatch(setReportViewed(report.productCode));
    }

    const link = addQueryParam(report.link, 'isNativeApp', !isWeb);

    switch (report.viewType) {
      case VIEW_TYPES.PDF: {
        dispatch(downloadReport(link, { report: productCode, place }));
        break;
      }
      case VIEW_TYPES.ONLINE: {
        if (pdfReportsBannerConfig?.openOnNewTabEnabled) {
          Linking.openURL(link);
        } else {
          navigate(ROUTES.REPORTS_WEB_VIEW, { link, place, report: productCode });
        }
        break;
      }
      case VIEW_TYPES.APP_SECTION: {
        navigateToScreen();
        break;
      }
      default: {
        console.log('[Unknown viewType]: ', report.viewType);
        break;
      }
    }
  };
};

export const showGetPDFReportsModal = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    if (!isWeb) {
      return false;
    }

    const state = getState();
    const { sessionNumber, showSessionsLimit, timeout } = state.remoteConfig.remoteConfigParams?.pdfReportsModalConfig || {};
    const isOnboardingCompleted = state.onboarding.isOnboardingCompleted;
    const currentSession = (Analytics.getSessionNumber() ?? 0) + 1;
    const endSession = sessionNumber + showSessionsLimit;
    const availableReportsToBuy = getAvailableReportsToBuy(state);

    if (availableReportsToBuy.length && currentSession >= sessionNumber && currentSession <= endSession && isOnboardingCompleted) {
      setTimeout(() => {
        const {
          navigation: { currentRoute },
          modals: { activeModal },
        } = getState();

        if (!activeModal && !ROUTES.MODALS_EXCLUDE_ROUTES.includes(currentRoute)) {
          dispatch(showModal(MODALS.GET_PDF_GUIDE));
        }
      }, timeout * 1000);
    }
  };
};

export const checkBirthChartAvailableForInApp = (): AppThunk<boolean> => {
  return (_dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      billing: { webTransactions },
      upsails: { userReports },
      remoteConfig: {
        remoteConfigParams: { birthChartAvailableForInAppFromDate: fromDate },
      },
    } = state;

    const isOldSubscriber = !!webTransactions.find((tr: any) => moment(fromDate).isAfter(tr.created_at, 'day'));
    const purchasedBirthChart = !!userReports.find(report => report.productCode === REPORTS_PRODUCT_CODES.BIRTH_CHART);

    return purchasedBirthChart || isOldSubscriber;
  };
};

export const unlockBirthChart = (onSuccess: () => void): AppThunk => {
  return (dispatch: AppDispatch) => {
    const isAvailable = dispatch(checkBirthChartAvailableForInApp());

    if (isAvailable) {
      return onSuccess();
    }

    dispatch(showModal(MODALS.WEB_BIRTH_CHART));
  };
};
