import moment from 'moment';
import { createAction } from 'redux-actions';
import _ from 'lodash';

import Analytics from 'analytics';
import { LONG_PATTERN } from 'constants/moment';
import { WHOSE_HAND_TYPES, Steps, STEPS } from 'constants/palm-reading-daily';
import { getRescanTimerByStep } from 'utils/rescan';
import { getPalm } from 'api/palm-reading';
import { downloadImage } from 'utils/process-image';

import { AppDispatch, AppGetState } from '../index';

import { TYPES, KEYS, PalmReadingData } from './types';

const setHandPhoto = createAction(TYPES.SET_PHOTO);
export const setPalmResult = createAction(TYPES.SET_WEB_DATA);
const setRescanTimer = createAction(TYPES.SET_RESCAN_TIMER);
const setRescanCounterAction = createAction(TYPES.SET_RESCAN_COUNTER);
const scheduleNextReportAction = createAction(TYPES.SCHEDULE_NEXT_REPORT);
const clearReportScheduleAction = createAction(TYPES.CLEAR_REPORT_SCHEDULE);
const setNextDailyScanDateAction = createAction(TYPES.SET_DAILY_NEXT_SCAN_DATE);
const setDailyAvailableToScanAction = createAction(TYPES.SET_DAILY_AVAILABLE_TO_SCAN);
export const completeStepAction = createAction(TYPES.COMPLETE_STEP);
export const setPreviewPhoto = createAction(TYPES.SET_PREVIEW_PHOTO);
export const setWebDataShowed = createAction(TYPES.SET_WEB_DATA_SHOWED);

/**
 * [params]
 * stepId => LEFT_HAND || RIGHT_HAND || FINGERS
 * pathType => Today
 * whoseHandType => MY_HAND || NOT_MINE
 */
export const completeStep = (stepId: Steps, pathType: string, day: number, whoseHandType?: WHOSE_HAND_TYPES) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { rescan },
      },
      palmReadingDaily: { rescanTimers, rescanCounters, preview, data },
    } = getState();

    if (preview) {
      let key = KEYS.LEFT;
      switch (stepId) {
        case STEPS.RIGHT_HAND:
          key = KEYS.RIGHT;
          break;
        case STEPS.FINGERS:
          key = KEYS.FINGERS;
          break;
      }

      dispatch(
        setHandPhoto({
          ...preview,
          key,
        }),
      );
    }

    const isRescan = !!whoseHandType;
    const isLeftHand = isRescan && stepId === STEPS.LEFT_HAND;
    const isRescanAvailable = getRescanTimerByStep(rescanTimers, stepId)?.isAvailable;
    const currentRescanCount = rescanCounters[stepId] + 1;

    Analytics.trackEvent('PRD', 'Day_Completed', {
      path_type: pathType,
      day,
      is_rescan: isRescan,
      rescan_timer_is_over: isRescanAvailable,
    });

    const isAvailableToGenerateNewReport =
      !isRescan || whoseHandType === WHOSE_HAND_TYPES.NOT_MINE || (isRescanAvailable && !isLeftHand && currentRescanCount % 2 === 0);

    const isForceGenerateNewReport = isLeftHand && data?.left_hand_path && currentRescanCount === 1;

    if (isAvailableToGenerateNewReport || isForceGenerateNewReport) {
      dispatch(completeStepAction({ stepId }));
    }

    if (rescan?.enabled) {
      const timer = moment().add(rescan.timeout, 'days').format(LONG_PATTERN);
      dispatch(setRescanTimer({ [stepId]: timer }));

      if (isRescan) {
        dispatch(
          setRescanCounterAction({
            stepId,
            counter: currentRescanCount,
          }),
        );
      }
    }
  };
};

export const scheduleNextReport = (pathType: string, day: number) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const minutes = getState().remoteConfig.remoteConfigParams.prdReportsTimeout;
    if (typeof minutes === 'number' && minutes > 0) {
      Analytics.trackEvent('PRD', 'Timer_Started', { path_type: pathType, day });
    }
    dispatch(scheduleNextReportAction({ minutes }));
  };
};

export const clearReportSchedule = (day: number, pathType?: string) => {
  return (dispatch: AppDispatch) => {
    Analytics.trackEvent('PRD', 'Timer_Ended', { path_type: pathType, day });
    dispatch(clearReportScheduleAction());
  };
};

export const setNextDailyScanDate = () => {
  return (dispatch: AppDispatch) => {
    const nextDay = moment().add(1, 'days');
    const nextScanDate = moment(nextDay).set({ hour: 6, minute: 0 });
    dispatch(setNextDailyScanDateAction(nextScanDate));
  };
};

export const checkNextDailyScanAvailable = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const { nextDailyScanDate } = getState().palmReadingDaily;

    if (!nextDailyScanDate) {
      return false;
    }

    const hoursLeftToNextScan = moment().diff(nextDailyScanDate, 'minutes');
    const isAvailable = hoursLeftToNextScan >= 0;
    return dispatch(setDailyAvailableToScanAction(isAvailable));
  };
};

export const getWebData = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const { data } = getState().palmReadingDaily;

    if (!_.isEmpty(data)) {
      return false;
    }

    try {
      const { palm_reading } = await getPalm();

      const leftHandOverviewResults: PalmReadingData['leftHandOverviewResults'] = palm_reading?.left_hand_dots
        ? _.get(JSON.parse(palm_reading.left_hand_dots), 'overviewHandResults', null)
        : null;

      const handResult = await downloadImage(palm_reading?.left_hand_path, STEPS.LEFT_HAND);

      dispatch(
        setPalmResult({
          leftHandOverviewResults,
          leftHandImageUrl: palm_reading?.left_hand_path, // #AS-8741 здесь нужен url, во всех остальных случаях кроме чата будет использовано скачанное изображение
        }),
      );

      if (palm_reading?.left_hand_dots) {
        dispatch(
          setHandPhoto({
            image: handResult,
            hand: STEPS.LEFT_HAND,
            key: KEYS.LEFT,
          }),
        );
        dispatch(completeStepAction({ stepId: STEPS.LEFT_HAND }));
      }
    } catch (message) {
      return console.warn('> Get web palm [ERROR]', message);
    }
  };
};
