import React, { FC } from 'react';
import { StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { fs, paddingHorizontal, sw } from '@wowmaking/ui/src/utils';
import LinearGradient from 'react-native-linear-gradient';

import { t2 } from 'localization';
import * as COLORS from 'constants/colors';
import Text from 'components/text';

interface Props {
  style?: StyleProp<ViewStyle>;
}

const GiftBadge: FC<Props> = ({ style }) => (
  <LinearGradient
    style={[styles.wrap, style]}
    start={{ x: 0, y: 1 }}
    end={{ x: 0, y: 1 }}
    useAngle
    angle={150}
    colors={COLORS.GRADIENT_AQUA_TO_PURPLE}>
    <Text style={styles.title}>{t2('SUB_BENEFITS.GIFT')}</Text>
  </LinearGradient>
);

export default GiftBadge;

const styles = StyleSheet.create({
  wrap: {
    position: 'absolute',
    right: 0,
    top: -sw(10),
    paddingHorizontal: paddingHorizontal(10),
    height: sw(20),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: sw(15),
    zIndex: 2,
  },
  title: {
    fontSize: fs(13),
    lineHeight: sw(20),
    fontWeight: '600',
    textAlign: 'center',
    color: COLORS.WHITE,
  },
});
