import _ from 'lodash';
import { Image, Platform } from 'react-native';
import { createAction } from 'redux-actions';

import { AppDispatch, AppGetState, AppThunk } from 'store';
import CacheManager from 'utils/cache-manager';
import { allSettled } from 'utils/promises';
import { getStories, sendAnswer, sendDislike, sendEmoji, sendLike, sendView } from 'api/stories';
import { Answer, StoryItem, Story } from 'api/stories/interfaces';
import { LIKE } from 'screens/stories/constants';

import { TYPES } from './types';

const setStories = createAction(TYPES.SET);
const setCacheId = createAction(TYPES.SET_CACHE_ID);
const setFirstTimeAction = createAction(TYPES.SET_FIRST_TIME);

export const setFirstTime = () => {
  return (dispatch: AppDispatch) => {
    return dispatch(setFirstTimeAction());
  };
};

export const initStories = () => {
  return (dispatch: AppDispatch) => {
    dispatch(loadStories());
  };
};

export const loadStories = (): AppThunk<Promise<Story[]>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      auth: { id, accessToken },
      stories: { stories, cacheId },
    } = getState();

    if (!accessToken) {
      return [];
    }

    if (id === cacheId && stories?.length) {
      return stories;
    }

    const response = await getStories();

    if (response.length) {
      dispatch(setStories(response));
      dispatch(setCacheId(id));
    }

    return response;
  };
};

export const setEmoji = (storyId: StoryItem['id'], emoji: any) => {
  return (dispatch: AppDispatch) => {
    return sendEmoji(storyId, emoji).finally(() => {
      return dispatch(updateStory(storyId, { emoji }));
    });
  };
};

export const setFeedback = (storyId: StoryItem['id'], feedback: any) => {
  return (dispatch: AppDispatch) => {
    const feedbackFunction = feedback === LIKE ? sendLike : sendDislike;

    return feedbackFunction(storyId).finally(() => {
      return dispatch(updateStory(storyId, { feedback }));
    });
  };
};

export const setView = (storyId: StoryItem['id']) => {
  return (dispatch: AppDispatch) => {
    return sendView(storyId).finally(() => {
      return dispatch(updateStory(storyId, { is_viewed: true }));
    });
  };
};

export const setAnswer = (storyId: StoryItem['id'], answerId: Answer['id']) => {
  return (dispatch: AppDispatch) => {
    return sendAnswer(answerId).finally(() => {
      return dispatch(updateStory(storyId, { answerId }));
    });
  };
};

export const prefetchStories = (storyId: StoryItem['id'], loadOnlyFirst = false) => {
  return async (_dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const { stories } = state.stories;
    const story = _.find(stories, { id: storyId });
    const items = story?.stories;
    const allImages: string[] = [];

    try {
      if (items?.length) {
        if (loadOnlyFirst) {
          await Image.prefetch(items[0]?.media?.url);
        } else {
          for (let i = 0; i < items.length; i++) {
            allImages.push(items[i]?.media?.url);
          }

          if (Platform.OS !== 'web') {
            await allSettled(
              allImages.map(file => {
                return CacheManager.downloadAndCacheUrl(file, { inBg: false }).then(path => {
                  Image.prefetch(path);
                });
              }),
            );
          }
        }
      }

      return Promise.resolve(true);
    } catch (error) {
      console.log('[PREFETCH ERROR]', error);
    }
  };
};

const updateStory = (storyId: number, data: { emoji?: any; feedback?: any; is_viewed?: boolean; answerId?: Answer['id'] } = {}) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const { emoji, feedback, is_viewed, answerId } = data;
    const state = getState();
    const { stories } = state.stories;
    const newStories = _.cloneDeep<Story[]>(stories);
    const category = _.find(newStories, cat => !!_.find(cat.stories, { id: storyId }));

    if (!category) {
      return;
    }

    const story = _.find(category.stories, { id: storyId });

    if (!story) {
      return;
    }

    story.emoji = emoji || story.emoji;
    story.feedback = feedback || story.feedback;
    story.is_viewed = is_viewed || story.is_viewed;
    story.answers = updateAnswers(story.answers, answerId);

    return dispatch(setStories(newStories));
  };
};

const updateAnswers = (answers: Answer[], answerId?: Answer['id']) => {
  if (!answerId) {
    return answers;
  }

  const newAnswers = _.cloneDeep(answers);
  const answer = _.find(newAnswers, { id: answerId });

  if (!answer) {
    return answers;
  }

  answer.is_chosen = true;
  answer.chooses_count = answer.chooses_count + 1;

  return newAnswers;
};
