import moment from 'moment';

import Localization from 'localization';

export function toCapitalizeSentence(string) {
  const sentences = string
    .split('.')
    .map(el => el.trim())
    .filter(Boolean);
  const capitalizeSentences = sentences.map(el => {
    if (!el) {
      return '';
    }
    const [firstLetter, ...otherLetters] = el;
    const firstBigLetter = firstLetter.toUpperCase();
    return [firstBigLetter, ...otherLetters].join('');
  });

  return capitalizeSentences.join('. ');
}

export const getTextForLocale = textLocalization => {
  if (!textLocalization) {
    return '';
  }

  const currentLocale = Localization.getLngCode();
  const index = currentLocale.indexOf('-');
  const locale = index >= 0 ? currentLocale.substring(0, index) : currentLocale;

  return textLocalization[locale] || textLocalization.en;
};

export const getTimeWithAMPM = date => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;

  return hours + ':' + minutes + ' ' + ampm;
};

export const formatLargeNumber = number => {
  const MILLION = 1000000;
  const THOUSAND = 1000;

  if (Math.abs(number) >= MILLION) {
    return (number / MILLION).toFixed(1) + 'M';
  }
  if (Math.abs(number) >= THOUSAND) {
    return (number / THOUSAND).toFixed(1) + 'K';
  }

  return number;
};

export const getTimeString = seconds => {
  return moment().startOf('day').add(seconds, 'seconds').format('mm:ss');
};

export const removeSpaces = string => {
  return string?.replace(/\s+/g, '') || '';
};

export const generateAlphabets = () => {
  const alphabets: string[] = [];
  let first = 'A'.charCodeAt(0);
  const last = 'Z'.charCodeAt(0);

  for (; first <= last; ++first) {
    alphabets.push(String.fromCharCode(first));
  }

  return alphabets;
};

export const getDisplayName = WrappedComponent => {
  return WrappedComponent.displayName || WrappedComponent.name;
};

export const isEmailValid = (email: string) => {
  const regex =
    /^[a-z\u2E80-\u9FFF0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z\u2E80-\u9FFF0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z\u2E80-\u9FFF0-9](?:[a-z\u2E80-\u9FFF0-9-]*[a-z\u2E80-\u9FFF0-9])?\.)+[a-z\u2E80-\u9FFF0-9](?:[a-z\u2E80-\u9FFF0-9-]*[a-z\u2E80-\u9FFF0-9])?$/i;
  return regex.test(email);
};

export const wordCount = val => {
  if (val) {
    return val.trim().split(/\s+/).length;
  } else {
    return 0;
  }
};

export const getRandomTexts = (array, count) => {
  const shuffledArray = array.sort(() => 0.5 - Math.random());
  return shuffledArray.slice(0, count);
};

export const capitalizeFirstLetter = <S extends string>(str: string) => {
  return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<S>;
};

export const capitalize = <S extends string>(str: string) => {
  if (str.includes('-')) {
    const words = str.split('-');
    const upWords = words.map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return upWords.join('-') as Capitalize<Lowercase<S>>;
  }

  return (str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()) as Capitalize<Lowercase<S>>;
};

export const upperCase = <T extends string>(str: T): Uppercase<T> => {
  return str.toUpperCase() as Uppercase<T>;
};

export const isPasswordValid = (password: string) => {
  const regex = /^(?=.*[\p{L}])(?=.*\d)[^\s]+$/u;
  return regex.test(password);
};
