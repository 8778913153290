import React, { FC, useCallback, memo } from 'react';
import { StyleSheet, StyleProp, ViewStyle, View } from 'react-native';

import { useAppDispatch } from 'store';
import { openReport } from 'store/upsails/actions';
import { UserReport } from 'store/upsails/types';
import { OPEN_REPORTS_PLACES } from 'constants/upsale';

import Item from './item';

interface Props {
  reports: UserReport[];
  withAnimation?: boolean;
  place: OPEN_REPORTS_PLACES;
  style?: StyleProp<ViewStyle>;
  onPress?: (id: UserReport['productCode']) => void;
}

const PersonalReportsList: FC<Props> = ({ reports, withAnimation, place, style, onPress }) => {
  const dispatch = useAppDispatch();

  const handleItemPress = useCallback((productCode: UserReport['productCode'], isTimeUp: boolean) => {
    dispatch(openReport(productCode, place, isTimeUp));

    if (typeof onPress === 'function') {
      onPress(productCode);
    }
  }, []);

  return (
    <View style={[styles.wrap, style]}>
      {reports.map((report: UserReport) => {
        return (
          <Item
            key={report.id}
            report={report}
            withAnimation={withAnimation}
            disabled={place === OPEN_REPORTS_PLACES.PROCESS_MODAL}
            onPress={handleItemPress}
          />
        );
      })}
    </View>
  );
};

export default memo(PersonalReportsList);

const styles = StyleSheet.create({
  wrap: {
    width: '100%',
  },
});
