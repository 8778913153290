import { isString } from 'lodash';

const regex = /<[^>]*>/gi;
const spacesRegEx = /\s\s+/g;

export const formatTextWithoutTags = (text: string): string => {
  if (!isString(text)) {
    return '';
  }

  const formattedText = text.split('\n').join('').replace(regex, '\n').replace(spacesRegEx, ' ').trim();

  return formattedText;
};
