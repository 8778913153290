import queryString from 'query-string';

export const addQueryParam = (url: string, key: string, value: any) => {
  const [baseUrl, searchParams] = url.split('?');
  const queryParams = queryString.parse(searchParams || '');
  queryParams[key] = value;

  const updatedQuery = queryString.stringify(queryParams);
  return updatedQuery ? `${baseUrl}?${updatedQuery}` : baseUrl;
};
