import { Story } from 'api/stories/interfaces';
import { PROFILE_TYPES } from 'store/profile/types';

export enum TYPES {
  SET = 'STORIES/SET',
  SET_CACHE_ID = 'STORIES/SET_CACHE_ID',
  SET_FIRST_TIME = 'STORIES/SET_FIRST_TIME',
}

export interface State {
  stories: Story[];
  cacheId: string | number;
  isFirstTime: boolean;
}

interface SetStories {
  type: typeof TYPES.SET;
  payload: Story[];
}

interface SetCacheId {
  type: typeof TYPES.SET_CACHE_ID;
  payload: string | number;
}

interface SetFirstTime {
  type: typeof TYPES.SET_FIRST_TIME;
}

interface ResetUserData {
  type: typeof PROFILE_TYPES.RESET_USER_DATA;
}

export type ActionType = SetStories | SetFirstTime | SetCacheId | ResetUserData;
