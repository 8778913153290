import { Action, ImageResult, SaveOptions, ActionCrop, ActionResize, ActionRotate } from './types.web';

export const manipulateAsync = async (uri: string, actions: Action[] = [], options: SaveOptions): Promise<ImageResult> => {
  const originalCanvas = await loadImageAsync(uri);

  const resultCanvas = actions.reduce((canvas, action) => {
    if ('crop' in action) {
      return crop(canvas, action.crop);
    } else if ('resize' in action) {
      return resize(canvas, action.resize);
    } else if ('rotate' in action) {
      return rotate(canvas, action.rotate);
    } else {
      return canvas;
    }
  }, originalCanvas);
  return getResults(resultCanvas, options);
};

function getResults(canvas: HTMLCanvasElement, options?: SaveOptions): ImageResult {
  let base64;
  if (options) {
    const { format = 'jpeg' } = options;
    if (options.format === 'png' && options.compress !== undefined) {
      console.warn('compress is not supported with png format.');
    }
    const quality = Math.min(1, Math.max(0, options.compress ?? 1));
    base64 = canvas.toDataURL('image/' + format, quality);
  } else {
    // defaults to PNG with no loss
    base64 = canvas.toDataURL();
  }
  return {
    uri: base64,
    width: canvas.width,
    height: canvas.height,
    base64,
  };
}

function loadImageAsync(uri: string): Promise<HTMLCanvasElement> {
  return new Promise((resolve, reject) => {
    const imageSource = new Image();
    imageSource.crossOrigin = 'anonymous';
    const canvas = document.createElement('canvas');
    imageSource.onload = () => {
      canvas.width = imageSource.naturalWidth;
      canvas.height = imageSource.naturalHeight;

      const context = getContext(canvas);
      context.drawImage(imageSource, 0, 0, imageSource.naturalWidth, imageSource.naturalHeight);

      resolve(canvas);
    };
    imageSource.onerror = () => reject(canvas);
    imageSource.src = uri;
  });
}

export const crop = (canvas: HTMLCanvasElement, options: ActionCrop['crop']) => {
  // ensure values are defined.
  let { originX = 0, originY = 0, width = 0, height = 0 } = options;

  const clamp = (value, max) => Math.max(0, Math.min(max, value));
  // lock within bounds.
  width = clamp(width, canvas.width);
  height = clamp(height, canvas.height);
  originX = clamp(originX, canvas.width);
  originY = clamp(originY, canvas.height);

  // lock sum of crop.
  width = Math.min(originX + width, canvas.width) - originX;
  height = Math.min(originY + height, canvas.height) - originY;

  if (width === 0 || height === 0) {
    throw new Error('Crop size must be greater than 0: ' + JSON.stringify(options, null, 2));
  }

  const result = document.createElement('canvas');
  result.width = width;
  result.height = height;

  const context = getContext(result);
  context.drawImage(canvas, originX, originY, width, height, 0, 0, width, height);

  return result;
};

export const resize = (canvas: HTMLCanvasElement, { width, height }: ActionResize['resize']) => {
  const imageRatio = canvas.width / canvas.height;

  let requestedWidth: number = 0;
  let requestedHeight: number = 0;
  if (width !== undefined) {
    requestedWidth = width;
    requestedHeight = requestedWidth / imageRatio;
  }
  if (height !== undefined) {
    requestedHeight = height;
    if (requestedWidth === 0) {
      requestedWidth = requestedHeight * imageRatio;
    }
  }

  return resampleSingle(canvas, requestedWidth, requestedHeight, true);
};

const getContext = (canvas: HTMLCanvasElement): CanvasRenderingContext2D => {
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Failed to create canvas context');
  }
  return ctx;
};

function resampleSingle(canvas: HTMLCanvasElement, width: number, height: number, resizeCanvas: boolean = false): HTMLCanvasElement {
  const result = document.createElement('canvas');
  result.width = canvas.width;
  result.height = canvas.height;

  const widthSource = canvas.width;
  const heightSource = canvas.height;
  width = Math.round(width);
  height = Math.round(height);

  const wRatio = widthSource / width;
  const hRatio = heightSource / height;
  const wRatioHalf = Math.ceil(wRatio / 2);
  const hRatioHalf = Math.ceil(hRatio / 2);

  const ctx = getContext(canvas);

  const img = ctx.getImageData(0, 0, widthSource, heightSource);
  const img2 = ctx.createImageData(width, height);
  const data = img.data;
  const data2 = img2.data;

  for (let j = 0; j < height; j++) {
    for (let i = 0; i < width; i++) {
      const x2 = (i + j * width) * 4;
      let weight = 0;
      let weights = 0;
      let weightsAlpha = 0;
      let gx_r = 0;
      let gx_g = 0;
      let gx_b = 0;
      let gx_a = 0;
      const yCenter = (j + 0.5) * hRatio;
      const yy_start = Math.floor(j * hRatio);
      const yy_stop = Math.ceil((j + 1) * hRatio);
      for (let yy = yy_start; yy < yy_stop; yy++) {
        const dy = Math.abs(yCenter - (yy + 0.5)) / hRatioHalf;
        const center_x = (i + 0.5) * wRatio;
        const w0 = dy * dy; //pre-calc part of w
        const xx_start = Math.floor(i * wRatio);
        const xx_stop = Math.ceil((i + 1) * wRatio);
        for (let xx = xx_start; xx < xx_stop; xx++) {
          const dx = Math.abs(center_x - (xx + 0.5)) / wRatioHalf;
          const w = Math.sqrt(w0 + dx * dx);
          if (w >= 1) {
            //pixel too far
            continue;
          }
          //hermite filter
          weight = 2 * w * w * w - 3 * w * w + 1;
          const xPosition = 4 * (xx + yy * widthSource);
          //alpha
          gx_a += weight * data[xPosition + 3];
          weightsAlpha += weight;
          //colors
          if (data[xPosition + 3] < 255) {
            weight = (weight * data[xPosition + 3]) / 250;
          }
          gx_r += weight * data[xPosition];
          gx_g += weight * data[xPosition + 1];
          gx_b += weight * data[xPosition + 2];
          weights += weight;
        }
      }
      data2[x2] = gx_r / weights;
      data2[x2 + 1] = gx_g / weights;
      data2[x2 + 2] = gx_b / weights;
      data2[x2 + 3] = gx_a / weightsAlpha;
    }
  }

  //resize canvas
  if (resizeCanvas) {
    result.width = width;
    result.height = height;
  }

  //draw
  const context = getContext(result);
  context.putImageData(img2, 0, 0);

  return result;
}

export const rotate = (canvas: HTMLCanvasElement, degrees: ActionRotate['rotate']) => {
  const { width, height } = sizeFromAngle(canvas.width, canvas.height, degrees);

  const result = document.createElement('canvas');
  result.width = width;
  result.height = height;

  const context = getContext(result);

  // Set the origin to the center of the image
  context.translate(result.width / 2, result.height / 2);

  // Rotate the canvas around the origin
  const radians = (degrees * Math.PI) / 180;
  context.rotate(radians);

  // Draw the image
  context.drawImage(canvas, -canvas.width / 2, -canvas.height / 2, canvas.width, canvas.height);

  return result;
};
function sizeFromAngle(width: number, height: number, angle: number): { width: number; height: number } {
  const radians = (angle * Math.PI) / 180;
  let c = Math.cos(radians);
  let s = Math.sin(radians);
  if (s < 0) {
    s = -s;
  }
  if (c < 0) {
    c = -c;
  }
  return { width: height * s + width * c, height: height * c + width * s };
}
