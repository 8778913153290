import moment from 'moment';
import { Platform } from 'react-native';
import { createAction } from 'redux-actions';

import Analytics from 'analytics';
import { createPalmReport, getPalmReports as requestPalmReports } from 'api/palm-reading';
import { LONG_PATTERN } from 'constants/moment';
import { PATH_TYPE, STEPS, WHOSE_HAND_TYPES } from 'constants/palm-reading-daily';
import { AppDispatch, AppGetState } from 'store';
import { getDocumentDirectory } from 'utils/cache-manager';
import { downloadImage } from 'utils/process-image';

import { HANDS, PalmMetadata, TYPES } from './types';

const setReports = createAction(TYPES.SET_REPORTS);
const setReport = createAction(TYPES.SET_REPORT);
const setReadyTimer = createAction(TYPES.SET_READY_TIMER);
const setRescanTimer = createAction(TYPES.SET_RESCAN_TIMER);
export const setPending = createAction(TYPES.SET_PENDING);
const setReportPreview = createAction(TYPES.SET_REPORT_PREVIEW);

// TODO TMP. DELETE !!!!! after Palm release 4.34
export const migrate = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const {
        palmReading: { reports },
        palmReadingDaily,
      } = getState();

      const leftHandImage = palmReadingDaily.left?.image;
      const rightHandImage = palmReadingDaily.right?.image;

      if (!reports?.[HANDS.LEFT]?.created_at && leftHandImage?.uri) {
        const uri = Platform.OS === 'web' ? leftHandImage.uri : getDocumentDirectory() + leftHandImage?.uri?.split('/')?.pop();
        await dispatch(createReport(uri, {}, HANDS.LEFT, PATH_TYPE.MIGRATION));
        Analytics.track('PRD_Migration_Success', { hand: HANDS.LEFT });
      }

      if (!reports?.[HANDS.RIGHT]?.created_at && rightHandImage?.uri) {
        const uri = Platform.OS === 'web' ? rightHandImage.uri : getDocumentDirectory() + rightHandImage?.uri?.split('/')?.pop();
        await dispatch(createReport(uri, {}, HANDS.RIGHT, PATH_TYPE.MIGRATION));
        Analytics.track('PRD_Migration_Success', { hand: HANDS.RIGHT });
      }
    } catch (e) {
      Analytics.track('PRD_Migration_Error', { error: String(e) });
      console.log('> Migrate to new palm report error', e);
    }
  };
};

export const createReport = (uri: string, metadata: PalmMetadata, hand: HANDS, pathType: PATH_TYPE, whoseHand?: WHOSE_HAND_TYPES) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const { rescan } = getState().remoteConfig.remoteConfigParams;
    const isRescan = !!whoseHand;

    try {
      const preview = { uri, date: '' };
      dispatch(setReportPreview({ [hand]: preview }));

      const report = await createPalmReport(uri, metadata, hand);
      preview.date = report.created_at;
      dispatch(setReport({ [hand]: report }));
      dispatch(setReportPreview({ [hand]: preview }));
      dispatch(setPending({ [hand]: false }));

      Analytics.track('PRD_Create_Report_Success', { path_type: pathType, hand, is_rescan: isRescan });

      if (rescan?.enabled && isRescan) {
        const timer = moment().add(rescan.timeout, 'days').format(LONG_PATTERN);
        dispatch(setRescanTimer({ [hand]: timer }));
      }
    } catch (e) {
      dispatch(setPending({ [hand]: false }));
      console.log('> Create report error', e);
      Analytics.track('PRD_Create_Report_Error', { error: (e as Error).message, path_type: pathType, hand, is_rescan: isRescan });
    }
  };
};

export const setUpReadyTimer = (hand: HANDS) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const minutes = getState().remoteConfig.remoteConfigParams.prdReportsReadyDelay;
    if (minutes && minutes > 0) {
      dispatch(setReadyTimer({ [hand]: moment().add(minutes, 'minutes').valueOf() }));
    }
  };
};

export const getPalmReports = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const { previews } = getState().palmReading;
    const { remoteConfigParams } = getState().remoteConfig;

    if (!remoteConfigParams?.palmReadingLandingEnabled) {
      return false;
    }

    try {
      const reports = await requestPalmReports();
      dispatch(setReports(reports));

      if (reports?.left?.created_at && reports?.left?.created_at !== previews?.left?.date) {
        try {
          const image = await downloadImage(reports.left.path, STEPS.LEFT_HAND);
          const preview = { uri: image.uri, date: reports.left.created_at };
          dispatch(setReportPreview({ left: preview }));
          Analytics.track('PRD_Report_Photo_Downloaded', { hand: HANDS.LEFT });
        } catch (e) {
          Analytics.track('PRD_Report_Photo_Download_Error', { hand: HANDS.LEFT, error: String(e) });
          console.log('> Download Left palm report photo error', e);
        }
      }

      if (reports?.right?.created_at && reports?.right?.created_at !== previews?.right?.date) {
        try {
          const image = await downloadImage(reports.right.path, STEPS.RIGHT_HAND);
          const preview = { uri: image.uri, date: reports.right.created_at };
          dispatch(setReportPreview({ right: preview }));
          Analytics.track('PRD_Report_Photo_Downloaded', { hand: HANDS.RIGHT });
        } catch (e) {
          Analytics.track('PRD_Report_Photo_Download_Error', { hand: HANDS.RIGHT, error: String(e) });
          console.log('> Download Right palm report photo error', e);
        }
      }

      // TODO !!!!!! TMP. Delete after release Palm release 4.34 !!!
      dispatch(migrate());
    } catch (e) {
      console.log('> Get reports error', e);
    }
  };
};
