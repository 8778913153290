import { WEB_PAYMENT_SERVICES, WEB_SUBSCRIPTION_STATUS, WEB_SUBSCRIPTION_STATE, PERIOD_TYPES } from 'modules/payments/constants';
import { Currency } from 'modules/payments/interfaces';
import { OneTimeProducts } from 'modules/payments/interfaces/product';
import { ApplePayDetailsPaymentData } from 'modules/payments/apple-pay/interfaces';

/**
 * Represents the currency details for a web product.
 */
interface WebProductCurrency {
  /**
   * The amount in the specified currency.
   */
  amount: string;

  /**
   * The currency type.
   */
  currency: Currency;

  /**
   * The trial price amount in the specified currency.
   */
  trial_price_amount: string;

  /**
   * The country associated with the currency.
   */
  country: string;
}

/**
 * Represents a web product.
 */
export interface WebProduct {
  /**
   * The resource type of the product.
   */
  resource_type: string;

  /**
   * The unique identifier of the product.
   */
  id: string;

  /**
   * The amount of the product.
   */
  amount: string;

  /**
   * The currency of the product.
   */
  currency: Currency;

  /**
   * The number of trial period days.
   */
  trial_period_days: string;

  /**
   * The trial price amount.
   */
  trial_price_amount: string;

  /**
   * The payment link for the product.
   */
  pay_link: null;

  /**
   * The period type of the product.
   */
  period: PERIOD_TYPES;

  /**
   * The list of currencies available for the product.
   */
  currencies: WebProductCurrency[];
}

/**
 * Represents a web purchase.
 */
export interface WebPurchase {
  /**
   * The resource type of the purchase.
   */
  resource_type: string;

  /**
   * The unique identifier of the purchase.
   */
  id: number;

  /**
   * The external identifier of the purchase.
   */
  external_id: string;

  /**
   * The payment service used for the purchase.
   */
  payment_service: WEB_PAYMENT_SERVICES;

  /**
   * Indicates if the purchase is active.
   */
  active: number;

  /**
   * The currency of the purchase.
   */
  currency: Currency;

  /**
   * The product associated with the purchase.
   */
  product: string;

  /**
   * The start date of the trial period.
   */
  trial_start_at: string | null;

  /**
   * The end date of the trial period.
   */
  trial_end_at: string | null;

  /**
   * The expiration date of the purchase.
   */
  expire_at: string;

  /**
   * The cancellation date of the purchase.
   */
  canceled_at: string | null;

  /**
   * The creation date of the purchase.
   */
  created_at: string;

  /**
   * The last update date of the purchase.
   */
  updated_at: string;

  /**
   * The email of the customer.
   */
  customer_email: string;

  /**
   * The amount of the purchase.
   */
  amount: number;

  /**
   * The email associated with the purchase.
   */
  email: string;

  /**
   * The signature of the purchase.
   */
  sig: string;

  /**
   * The state of the subscription.
   */
  state: WEB_SUBSCRIPTION_STATE;
}

/**
 * Represents a web subscription.
 */
export interface WebSubscription extends Omit<WebPurchase & WebProduct, 'amount'> {
  /**
   * The status of the subscription.
   */
  status: WEB_SUBSCRIPTION_STATUS;

  /**
   * The amount of the subscription.
   */
  amount: number;

  /**
   * Indicates if the subscription is in a trial period.
   */
  trial: number;

  /**
   * The trial period type.
   */
  trial_period: PERIOD_TYPES | null;

  /**
   * Indicates if the subscription is in an active trial period.
   */
  is_active_trial_period: boolean;
}

/**
 * Represents a one-time web purchase.
 */
export interface WebOneTimePurchase {
  /**
   * The unique identifier of the purchase.
   */
  id: number;

  /**
   * The payment service used for the purchase.
   */
  payment_service: WEB_PAYMENT_SERVICES;

  /**
   * The project associated with the purchase.
   */
  project: string;

  /**
   * The product code of the purchase.
   */
  product_code: OneTimeProducts;

  /**
   * The order identifier of the purchase.
   */
  order_id: string;

  /**
   * The email associated with the purchase.
   */
  email: string;

  /**
   * The amount of the purchase.
   */
  amount: number;

  /**
   * The currency of the purchase.
   */
  currency: Currency;

  /**
   * The user identifier associated with the purchase.
   */
  user_id: number;

  /**
   * The customer identifier associated with the purchase.
   */
  customer_id: string;

  /**
   * The subscription identifier associated with the purchase.
   */
  subscription_id: number;

  /**
   * The description of the purchase.
   */
  description: string;

  /**
   * The status of the purchase.
   */
  status: number;

  /**
   * The creation date of the purchase.
   */
  created_at: string;

  /**
   * The last update date of the purchase.
   */
  updated_at: string;

  /**
   * The trigger associated with the purchase.
   */
  trigger: string | null;

  /**
   * The metadata associated with the purchase.
   */
  metadata: string | null;
}

/**
 * Represents the metadata for a solid payment.
 */
export interface SolidMetadata {
  /**
   * The Adjust identifier.
   */
  adjust_id?: string;

  /**
   * The IDFM identifier.
   */
  idfm?: string;

  /**
   * The IDFV identifier.
   */
  idfv?: string;

  /**
   * The IDFA identifier.
   */
  idfa?: string;

  /**
   * The Firebase instance identifier.
   */
  firebase_instance_id?: string;

  /**
   * The web UUID.
   */
  web_uuid?: string;
}

/**
 * Represents the payment data.
 */
export interface PaymentData {
  /**
   * The amount of the payment.
   */
  amount: number;

  /**
   * The currency of the payment.
   */
  currency: Currency;

  /**
   * The product code associated with the payment.
   */
  productCode: string;

  /**
   * The metadata associated with the payment.
   */
  metadata: any;

  /**
   * The description of the payment.
   */
  description: string;

  /**
   * The product trigger associated with the payment.
   */
  productTrigger: string;

  /**
   * The solid metadata associated with the payment.
   */
  solidMetadata?: SolidMetadata;
}

/**
 * Represents the one-click payment data.
 */
export interface OneClickPaymentData extends PaymentData {}

/**
 * Represents the subscription payment data.
 */
export interface SubscriptionPaymentData extends Omit<PaymentData, 'productCode' | 'amount' | 'currency'> {
  /**
   * The product identifier associated with the subscription.
   */
  productId: string;
}

/**
 * Represents the Apple Pay payment data.
 */
export interface ApplePayPaymentData extends Omit<PaymentData, 'productTrigger'> {
  /**
   * The payment data.
   */
  data: string;

  /**
   * The header of the Apple Pay payment data.
   */
  header: ApplePayDetailsPaymentData['header'];

  /**
   * The signature of the Apple Pay payment data.
   */
  signature: ApplePayDetailsPaymentData['signature'];

  /**
   * The version of the Apple Pay payment data.
   */
  version: string;

  /**
   * The email associated with the payment.
   */
  email: string;

  /**
   * The trigger associated with the payment.
   */
  trigger: string;
}

/**
 * Represents the Google Pay payment data.
 */
export interface GooglePayPaymentData extends Omit<PaymentData, 'productTrigger'> {
  /**
   * The signature of the Google Pay payment data.
   */
  signature: string;

  /**
   * The signed message of the Google Pay payment data.
   */
  signedMessage: string;

  /**
   * The protocol version of the Google Pay payment data.
   */
  protocolVersion: string;

  /**
   * The merchant associated with the payment.
   */
  merchant: string;

  /**
   * The platform of the payment.
   */
  platform: 'APP';

  /**
   * The email associated with the payment.
   */
  email: string;

  /**
   * The trigger associated with the payment.
   */
  trigger: string;
}

/**
 * Enum representing the payment methods.
 */
export enum PaymentMethods {
  /**
   * Card payment method.
   */
  CARD = 'card',

  /**
   * PayPal payment method.
   */
  PAYPAL = 'paypal',

  /**
   * Apple Pay payment method.
   */
  APPLE_PAY = 'applepay',

  /**
   * Google Pay payment method.
   */
  GOOGLE_PAY = 'googlepay',
}

/**
 * Represents the response for a one-time payment.
 */
export interface OnetimePaymentResponse {
  /**
   * The URL to verify the payment.
   */
  verify_url?: string;

  /**
   * The details of the one-time payment.
   */
  one_time_payment: {
    /**
     * The payment service used for the payment.
     */
    payment_service: WEB_PAYMENT_SERVICES;

    /**
     * The project associated with the payment.
     */
    project: string;

    /**
     * The order identifier of the payment.
     */
    order_id: string;

    /**
     * The product code of the payment.
     */
    product_code: string;

    /**
     * The email associated with the payment.
     */
    email: string;

    /**
     * The user identifier associated with the payment.
     */
    user_id: string;

    /**
     * The customer identifier associated with the payment.
     */
    customer_id: string;

    /**
     * The currency of the payment.
     */
    currency: string;

    /**
     * The amount of the payment.
     */
    amount: string;

    /**
     * The description of the payment.
     */
    description: string;

    /**
     * The status of the payment.
     */
    status: string;

    /**
     * The trigger associated with the payment.
     */
    trigger: string;

    /**
     * The metadata associated with the payment.
     */
    metadata: string;

    /**
     * The creation date of the payment.
     */
    created_at: string;

    /**
     * The last update date of the payment.
     */
    updated_at: string;

    /**
     * The unique identifier of the payment.
     */
    id: string;

    /**
     * The session number of the payment.
     */
    session_number: string;

    /**
     * The retention day of the payment.
     */
    retention_day: string;
  };
}

/**
 * Represents the response for a subscription payment.
 */
export interface SubscriptionPaymentResponse {
  /**
   * The URL to verify the payment.
   */
  verify_url?: string;

  /**
   * The details of the subscription payment.
   */
  data: {
    /**
     * The resource type of the subscription.
     */
    resource_type: string;

    /**
     * The transaction identifier of the subscription.
     */
    transaction_id: string;

    /**
     * The plan name of the subscription.
     */
    plan_name: string;

    /**
     * The email associated with the subscription.
     */
    email: string;

    /**
     * The currency of the subscription.
     */
    currency: Currency;

    /**
     * The amount of the subscription.
     */
    amount: number;

    /**
     * The customer identifier associated with the subscription.
     */
    customer_id: string;

    /**
     * The creation date of the subscription.
     */
    created_at: string;

    /**
     * The start date of the trial period.
     */
    trial_start_at: null | string;

    /**
     * The end date of the trial period.
     */
    trial_end_at: null | string;

    /**
     * The expiration date of the subscription.
     */
    expire_at: string;

    /**
     * The cancellation date of the subscription.
     */
    canceled_at: null | string;

    /**
     * The state of the subscription.
     */
    state: WEB_SUBSCRIPTION_STATE;

    /**
     * Indicates if the subscription is active.
     */
    is_active: true;

    /**
     * The provider of the subscription.
     */
    provider: WEB_PAYMENT_SERVICES;

    /**
     * The tracks associated with the subscription.
     */
    tracks: any[];
  };
}

/**
 * Represents the payment response, which can be either a one-time payment response or a subscription payment response.
 */
export type PaymentResponse = OnetimePaymentResponse | SubscriptionPaymentResponse;

/**
 * Represents a user report.
 */
export interface UserReport {
  /**
   * The resource type of the report.
   */
  resource_type: string;

  /**
   * The unique identifier of the report.
   */
  id: number;

  /**
   * The product code associated with the report.
   */
  product_code: string; // birth_chart | compatibility | ...

  /**
   * The title of the report.
   */
  title: string;

  /**
   * The image URL of the report.
   */
  image: string; // url

  /**
   * The link URL of the report.
   */
  link: string; // url

  /**
   * The view type of the report.
   */
  view_type: string; // pdf | online | app_section

  /**
   * The type of issue for the report.
   */
  type_of_issue: string; // one_time | gift | sub_gift

  /**
   * The ready timer for the report, in seconds.
   */
  ready_timer: number | null; // in seconds
}

/**
 * Represents the data for reports.
 */
export interface ReportsData {
  /**
   * The list of user reports.
   */
  user_reports: UserReport[];

  /**
   * The list of available reports.
   */
  available_reports: string[]; // birth_chart | compatibility | ...
}

/**
 * Represents the response for reports.
 */
export interface ReportsResponse {
  /**
   * The data for the reports.
   */
  data: ReportsData;
}
