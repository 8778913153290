import { createSelector } from 'reselect';

import { RootState } from 'store';
import { ONE_TIME_PRODUCTS } from 'modules/payments/constants/product';

import type { AstrologerUserData } from './types';

const getWebOneTimePurchases = (state: RootState) => state.billing.webOneTimePurchases;
const getCurrentChatId = (state: RootState) => state.astrologers.chat.currentChatId;
const getUserId = (state: RootState) => state.profile.profileData.id;
const getCurrentAstrologerId = (state: RootState) => state.astrologers.chat.currentAstrologerId;
const getCurrentTrigger = (state: RootState) => state.astrologers.monetization.currentTrigger;

export const getAstrologersConsultationsPurchases = createSelector([getWebOneTimePurchases], purchases => {
  return purchases.filter(purchase => purchase.product_code.indexOf(ONE_TIME_PRODUCTS.ASTROLOGER_CONSULTATION) >= 0);
});

export const getPurchasedConsultations = createSelector([getWebOneTimePurchases], purchases => {
  return purchases.filter(purchase => purchase.product_code.indexOf(ONE_TIME_PRODUCTS.ASTROLOGER_CONSULTATION) >= 0);
});

export const getAstrologerUserData = createSelector(
  [getUserId, getCurrentChatId, getCurrentAstrologerId, getCurrentTrigger],
  (userId, chatId, astrologerId, trigger): AstrologerUserData => ({ chatId, userId, astrologerId, trigger }),
);
