export enum TYPES_OF_ISSUE {
  ONE_TIME = 'one_time',
  GIFT = 'gift',
  SUB_GIFT = 'sub_gift',
}

export enum VIEW_TYPES {
  PDF = 'pdf',
  ONLINE = 'online',
  APP_SECTION = 'app_section',
}

export enum OPEN_REPORTS_PLACES {
  ASTRO_CALENDAR = 'astro_calendar',
  SETTINGS = 'settings',
  TODAY = 'today',
  PROCESS_MODAL = 'process_modal',
}

export enum REPORTS_PRODUCT_CODES {
  BIRTH_CHART = 'birth_chart',
  COMPATIBILITY = 'compatibility',
  PALM_READING = 'palm_reading',
}
