import { ERRORS } from 'constants/palm-reading-daily';
import type { ImageProcessed } from 'components/palm-reading/types';

import { manipulateAsync } from './actions.web';

export async function processImage(image): Promise<ImageProcessed> {
  // const w = image.height * (3 / 4);
  // image = await manipulateAsync(
  //   image.uri,
  //   [
  //     {
  //       crop: {
  //         width: w,
  //         height: image.height,
  //         originX: (image.width - w) / 2,
  //         originY: 0,
  //       },
  //     },
  //   ],
  //   {
  //     base64: true,
  //     compress: 0.8,
  //   },
  // );

  if (image?.hasOwnProperty('base64')) {
    delete image.base64;
  }

  return image;
}

export const processScreenShot = async (uri?: string | undefined): Promise<ImageProcessed> => {
  if (!uri?.length) {
    throw new Error(ERRORS.NO_URI_GIVEN);
  }

  const image = await manipulateAsync(uri, [], {
    base64: true,
    compress: 0.3,
  });

  if (image?.hasOwnProperty('base64')) {
    delete image.base64;
  }

  return image;
};

export const downloadImage = async (uri: string) => {
  return processScreenShot(uri);
};
