import React, { FC, memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { paddingHorizontal, paddingVertical } from '@wowmaking/ui/src/utils';

import { t2 } from 'localization';
import useCountDown from 'hooks/use-count-down';
import * as COLORS from 'constants/colors';
import Text from 'components/text';

interface Props {
  date: Date;
  isViewed: boolean;
  onTimeEnd: () => void;
}

const Timer: FC<Props> = ({ date, isViewed, onTimeEnd }) => {
  const { isTimeUp, formattedTime } = useCountDown(date, onTimeEnd);

  if (isTimeUp && isViewed) {
    return null;
  }

  const text = !isTimeUp ? t2('PERSONAL_REPORTS.TIMER_TEXT') : t2('PERSONAL_REPORTS.READY');

  return (
    <View style={styles.wrap}>
      <View style={styles.container}>
        <Text style={styles.text}>{text}</Text>
        {!isTimeUp && <Text style={styles.time}>{formattedTime}</Text>}
      </View>
    </View>
  );
};

export default memo(Timer);

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.TURQUOISE_BLUE,
    width: '100%',
    zIndex: -1,
    paddingTop: paddingVertical(10),
    marginTop: -paddingVertical(10),
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  container: {
    width: '100%',
    height: 25,
    paddingHorizontal: paddingHorizontal(15),
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: COLORS.WHITE,
    fontWeight: '500',
    lineHeight: 15,
    fontSize: 10,
  },
  time: {
    color: COLORS.WHITE,
    fontSize: 15,
    fontWeight: '700',
    marginLeft: paddingHorizontal(10),
  },
});
