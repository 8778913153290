import { State, ActionTypes, TYPES } from './types';

const initialState: State = {
  viewedReports: [],
  userReports: [],
  availableReports: [],
};

export default (state: State = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case TYPES.SET_REPORT_VIEWED:
      return {
        ...state,
        viewedReports: [...state.viewedReports, action.payload],
      };
    case TYPES.SET_REPORTS:
      return {
        ...state,
        userReports: action.payload.userReports,
        availableReports: action.payload.availableReports,
      };
    default:
      return state;
  }
};
