import React, { createContext } from 'react';

import defaultTheme, { Styles } from '../../default-styles';

export const StylesContext = createContext<Styles>(defaultTheme);

const StylesProvider = ({ children }) => {
  return <StylesContext.Provider value={defaultTheme}>{children}</StylesContext.Provider>;
};

export default StylesProvider;
