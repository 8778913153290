import React, { useEffect } from 'react';
import { Linking, LogBox, StyleSheet } from 'react-native';
import { Provider } from 'react-redux';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { PersistGate } from 'redux-persist/integration/react';

import Analytics from './analytics';
import Localization from './localization';
import Messaging from './messaging';
import StylesProvider from './modules/styles/hoc/styles-provider';
import App from './screens';
import Store from './store';
import { handleDeepLink, setInitialUrl } from './store/app/actions';

LogBox.ignoreLogs(['LinearGradient colors and locations props should be arrays of the same length', 'Possible Unhandled Promise Rejection']);

Localization.init();

export default function Application({ isHeadless }) {
  if (isHeadless) {
    return null;
  }

  const { store, persistor } = Store.init();

  Messaging.init();
  Analytics.putStore(store);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const deepLinkHandler = event => {
      if (event?.url) {
        store.dispatch(handleDeepLink(event?.url));
        store.dispatch(setInitialUrl(event?.url));
      }
    };

    const subscription = Linking.addEventListener('url', deepLinkHandler);

    Linking.getInitialURL().then(url => {
      if (url) {
        deepLinkHandler({ url });
      }
    });

    return () => {
      subscription?.remove?.();
    };
  }, [store]);

  return <Root store={store} persistor={persistor} />;
}

class Root extends React.PureComponent {
  render() {
    const { store, persistor } = this.props;

    return (
      <GestureHandlerRootView style={styles.root}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            {bootstrapped => {
              if (bootstrapped) {
                return (
                  <StylesProvider>
                    <App />
                  </StylesProvider>
                );
              } else {
                return null;
              }
            }}
          </PersistGate>
        </Provider>
      </GestureHandlerRootView>
    );
  }
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});
